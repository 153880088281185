import React, { ReactNode, useEffect, useRef, useState, } from "react";
import { Button, Select, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import config from "@app/utils/config";
import debounce from "lodash/debounce";
import styles from "./index.module.less";
import { uniqueArray } from "../../utils/array";
import logger from "@app/utils/logger";

export type InfinitySelectGetOptions = 'fetch' | 'load';

export interface ISelector {
  data?: any;
  getPaginatedRecords: (page: number, type: getOptions, keyValue: string) => Promise<Array<any>>;
  onChange: (value: any, selectedValues?: Array<any>) => void;
  onLoaded?: (isLoaded: boolean) => void;
  formatValue: (value: any) => any;
  mode?: "multiple" | "tags";
  notFoundContent: string;
  notLoadContent: string;
  value?: any;
  placeholder?: string;
  isDelete?: boolean;
  isDefault?: boolean;
  queryParameters?: {};
  excludeList?: Array<string>;
  preSelected?: Array<any>;
  defaultValues?: any;
  removeById?: any;
  maxTagCount?: any;
  maxTagPlaceHolder?: (value: any) => void;
  disabled?: boolean;
  returnObject?: boolean;
  newlyAddedValue?: any;
  hideSelected?: boolean;
  filterValues?: any;
  suffixIcon?: ReactNode;
  waitCharCount?: number;
  onTop?: boolean;
}

type getOptions = "fetch" | "load";

const DEFAULT_VIEW_SIZE = 15;

export default (props: ISelector) => {
  const dropdown = useRef({}) as any;
  const [loading, setLoading] = useState<boolean>(false);
  const [isEndOfScroll, setIsEndOfScroll] = useState<boolean>(false);
  const [isBottom, setIsBottom] = useState<boolean>(false);
  const [values, setValues] = useState<Array<string | number>>([]);
  const [options, setOptions] = useState<Array<any>>([]);
  const [keyValue, setKeyValue] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  window.addEventListener(
      "keydown",
      (event: any) => {
        if (event.key === "Backspace" && !event.target.value) {
          event.stopPropagation();
        }
      },
      true
  );

  const fetchInitialData = () => {
    setLoading(true);
    props.getPaginatedRecords(1, 'fetch', keyValue).then((res: any) => {
      setOptions(res.records);
      setPageCount(res.pageCount);
      setCurrentPage(res.pageNumber);
      setLoading(false);
      setValues(props.defaultValues);
    });
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const loadMore = () => {
    setIsEndOfScroll(false);
    setLoading(true);
    props.getPaginatedRecords(currentPage + 1, "load", keyValue).then((res: any) => {
      console.info('loadMore',uniqueArray([...options, ...res.records]))
      setOptions(uniqueArray([...options, ...res.records]));
      setPageCount(res.pageCount);
      setCurrentPage(res.pageNumber);
      setLoading(false);
    });
  };

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (keyVal: string) => {
      if (props.waitCharCount && keyVal.length < props.waitCharCount) {
        setKeyValue(keyVal);
        return;
      }
      setLoading(true);
      props.getPaginatedRecords(1, "fetch", keyVal).then((res: any) => {
        console.info('debounceFetcher',options,uniqueArray([...res.records,...options ]))

        setOptions(uniqueArray([...res.records, ...options]));
        setLoading(false);
      });
    };
    return debounce(loadOptions, config.inputDebounceInterval);
  }, [config.inputDebounceInterval, options]);

  const onVisibleChange = (visibleState: boolean) => {
    if (keyValue && !visibleState && props.mode) {
      setLoading(true);
      props.getPaginatedRecords(1, 'fetch',keyValue).then((res: any) => {
        console.info('onVisibleChange',uniqueArray([...options, ...res.records]))

        setOptions(uniqueArray([...options, ...res.records]));
        setPageCount(res.pageCount);
        setCurrentPage(res.pageNumber);
        setLoading(false);
      });
    }
  };

  const onScroll = (event: any) => {
    setIsBottom(
        event.target.scrollHeight - event.target.scrollTop ===
        event.target.clientHeight
    );
    const lastElement: any = document.getElementById(
        options ? options[(options || []).length - 1].id : "null"
    );
    const containerTop = dropdown
        ? dropdown?.current.getBoundingClientRect().top
        : null;
    const lastElementTopPos =
        lastElement?.getBoundingClientRect().top - containerTop;
    const containerHeight = dropdown?.current?.getBoundingClientRect().height;
    logger.debug('GenericDataTable','Infinity select - on scroll',{pageCount,currentPage})
    if (
        lastElementTopPos - DEFAULT_VIEW_SIZE < containerHeight &&
        pageCount > currentPage &&
        !loading
    ) {
      loadMore();
    } else {
      if (pageCount === currentPage) {
        setIsEndOfScroll(true);
      }
    }
  };

  const handleBlur = () => {
    props.getPaginatedRecords(1, 'fetch', '').then((res: any) => {
      console.info('handleBlur',options,uniqueArray([...options, ...res.records]))

      setKeyValue('');
      setOptions(uniqueArray([...options, ...res.records]));
      setPageCount(res.pageCount);
      setCurrentPage(res.pageNumber);
    });
  };

  return (
      <>
        <Select
            showSearch
            showArrow
            value={values}
            filterOption={false}
            disabled={props.disabled}
            notFoundContent={loading ? <Spin size="small" /> : props.notFoundContent}
            mode={props.mode}
            onSearch={(e) => {
              debounceFetcher(e);
            }}
            onFocus={() => {
            }}
            onBlur={() => {
              setLoading(true);
              setIsEndOfScroll(false);
              handleBlur();
            }}
            onChange={(value, option) => {
              console.info('OnChange', value, option)
              setValues(value);
              if (props.returnObject) {
                // props.onChange(
                //     value,
                //     historyOptions.filter((x) => value.includes(x.id))
                // );
              } else {
                props.onChange(value);
              }
            }}
            onDropdownVisibleChange={(e) => onVisibleChange(e)}
            defaultValue={values}
            onPopupScroll={(e) => onScroll(e)}
            getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
            placeholder={props.placeholder}
            maxTagCount={props.maxTagCount}
            // maxTagPlaceholder={(e) =>
            //   props.maxTagPlaceHolder && props.maxTagPlaceHolder(e)
            // }
            listHeight={200}
            dropdownRender={(menu) => (
                <div ref={dropdown} id="dropdown">
                  {menu}
                  <div className={styles.yjInfinityLoadMore}>
                    <Spin size="small" spinning={loading} />
                    {isEndOfScroll && isBottom && (
                        <div className={styles.yjInfinityScrollText}>
                          <p>You have seen it all !</p>
                        </div>
                    )}
                  </div>
                </div>
            )}
        >
          {options?.map((value: any) => {
            return (
                !props.excludeList?.includes(value.id) && (
                    <Select.Option key={value.id} id={value.id} value={value.id}>
                      {props.formatValue(value)}
                    </Select.Option>
                )
            );
          })}
        </Select>
        {props.isDelete && (
            <Button
                onClick={() => {
                  setValues([]);
                }}
            >
              <DeleteOutlined />
            </Button>
        )}
      </>
  );
};
