import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { configureStore } from "./app/redux/store/store";
import App from "./app/pages/App";
import "./styles/global.less";
import DiscoveryFEClient from "@iris/discovery.fe.client";
import { Skeleton } from "antd";
import config from "@app/utils/config";
import logger from "@app/utils/logger";

export const store = configureStore();

export const DiscoveryClient = new DiscoveryFEClient(config.discoveryURL);

const fetchColorData = async () => {
    try {
        const response = await fetch('/data/colors.json');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (error) {
        logger.error("GenericMethods", "fetchColorData", error);
        return null;
    }
};

const updateStyles = (colors:any) => {
    if (!colors) return;

    const updatedStyles = `
    :root {
      --color-primary: ${colors.primaryColor};
      --color-secondary: ${colors.secondaryColor};
      --color-tertiary: ${colors.tertiaryColor};
      --color-accent: ${colors.accentColor};
      --color-white: ${colors.whiteColor};
      --color-gray-light: ${colors.lightGrayColor};
      --color-gray-medium: ${colors.mediumGrayColor};
      --color-black: ${colors.blackColor};
    }
  `;

    const styleElement = document.createElement('style');
    styleElement.innerHTML = updatedStyles;
    document.head.appendChild(styleElement);
};

const PreLoad = () => {
  const [authURL, setAuthURL] = useState<string>();

  useEffect(() => {
    DiscoveryClient.getOAuthURL().then((url) => {
      setAuthURL(url);
    });
    const initialize = async () => {
        const colors = await fetchColorData();
        updateStyles(colors);
    };
    initialize();
  }, [DiscoveryClient]);

  if (authURL)
    return (
      <Provider store={store}>
        <App authUrl={authURL} />
      </Provider>
    );
  else return <Skeleton />;
};

ReactDOM.render(<PreLoad />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
